let API_URL;
let site;
const next_env = process.env.NEXT_PUBLIC_ENV.toLowerCase();

if (next_env === "development") {
  API_URL = "https://where-next-staging.herokuapp.com";
  site = "https://staging.wherenext.to";
} else if (next_env === "local") {
  API_URL = "http://localhost:1337";
  site = "http://localhost:3001";
} else if (next_env === "preview") {
  site = "https://preview.wherenext.to";
  API_URL = "https://where-next-strapi.herokuapp.com";
} else {
  API_URL = "https://where-next-strapi.herokuapp.com";
  site = "https://www.wherenext.to";
}

export { site, API_URL };

const currencies = {
  euro: {
    fullName: "Euro",
    code: "EUR",
    symbolCode: 8364,
    symbol: "€",
  },
  usd: {
    fullName: "United States Dollar",
    code: "USD",
    symbolCode: 36,
    symbol: "$",
  },
};

export const currency = currencies.usd;

export const SOCIAL_LINK = {
  email: "contact@wherenext.to",
  facebook: "https://www.facebook.com/wherenext.to.travel",
  instagram: "https://www.instagram.com/wherenext.to/",
  linkedin: "https://www.linkedin.com/company/wherenext-to/",
  messenger: "http://m.me/wherenext.to.travel",
  twitter: "https://twitter.com/wherenext_to",
  whatsapp: "https://wa.me/351920131761",
};
